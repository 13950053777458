
import {
  IonPage,
  IonContent,
  IonIcon,
  onIonViewDidEnter,
  onIonViewWillEnter,
} from "@ionic/vue";
import { chevronForward } from "ionicons/icons";
import { skipRouter } from "@/router";
import { ref } from "vue";
import { getUserDetail } from "@/service/member";
import { userinfo, checklogin } from "@/utils/usermodule";
import { local } from "@/utils/storage.service";

export default {
  name: "Member",
  components: { IonContent, IonPage, IonIcon },
  setup() {
    const pagedata: any = ref([]);
    const usertypename: any = ref("未知");

    const templates: any = ref([
      {
        title: "完善信息",
        path: "/tabs/member/personnel-info",
      },
      {
        title: "数据看板",
        path: "/tabs/member/data-dashboard",
      },
      {
        title: "反馈留言",
        path: "/tabs/member/feedback",
      },
      {
        title: "投标管理",
        path: "/tabs/member/tender-manage",
      },
      {
        title: "系统设置",
        path: "/tabs/member/setting",
      },
    ]);

    const getUserDetailInfo = async () => {
      const loginuser = local.get("loginuser");
      console.log(loginuser);

      if (loginuser == null || loginuser.islogin != "1") {
        usertypename.value = "未登录";
        return;
      }

      const result = await getUserDetail({
        userid: loginuser.userid,
      });
      pagedata.value = result;

      if (pagedata.value.isSystem == 1) {
        templates.value = [
          {
            title: "完善信息",
            path: "/tabs/member/personnel-info",
          },
          {
            title: "数据看板",
            path: "/tabs/member/data-dashboard",
          },
          {
            title: "反馈留言",
            path: "/tabs/member/feedback",
          },
          {
            title: "投标管理",
            path: "/tabs/member/tender-manage",
          },
          {
            title: "系统设置",
            path: "/tabs/member/setting",
          },
        ];
      } else {
        templates.value = [
          {
            title: "完善信息",
            path: "/tabs/member/personnel-info",
          },
          {
            title: "反馈留言",
            path: "/tabs/member/feedback",
          },
          {
            title: "投标管理",
            path: "/tabs/member/tender-manage",
          },
          {
            title: "系统设置",
            path: "/tabs/member/setting",
          },
        ];
      }

      if (pagedata.value.userType == 1) {
        usertypename.value = "技术工人";
        templates.value[0].path = "/tabs/member/personnel-info";
      } else if (pagedata.value.userType == 2) {
        usertypename.value = "供应商";
        templates.value[0].path = "/tabs/member/supplier-info";
      } else if (pagedata.value.userType == 0) {
        usertypename.value = "未知";
        templates.value[0].path = "/login/selected-identity";
      } else {
        usertypename.value = "未登录";
        templates.value[0].path = "/login";
      }

      console.log(pagedata.value);
    };

    const skipRoutervoid = (usertype: number) => {
      if (usertype == 1) {
        skipRouter(`/tabs/member/personnel-info`);
      } else if (usertype == 2) {
        skipRouter(`/tabs/member/supplier-info`);
      } else if (usertype == 0) {
        skipRouter(`/login/selected-identity`);
      } else {
        skipRouter(`/login`);
      }
    };
    //进入页面并且渲染完页面后触发，每次进入页面都触发
    onIonViewDidEnter(() => {
      console.log("onIonViewDidEnter!");

      //检测是否登录
      checklogin();
      //获取用户详情
      getUserDetailInfo();
    });
    //进入页面后触发，每次进入页面都触发
    onIonViewWillEnter(() => {
      console.log("onIonViewWillEnter!");
    });

    return {
      chevronForward,
      templates,
      skipRouter,
      skipRoutervoid,
      pagedata,
      usertypename,
    };
  },
};
